'use client';

import { useEffect } from 'react';
import Link from 'next/link';
import { ChevronLeftIcon, RotateCwIcon } from 'lucide-react';
import { usePostHog } from 'posthog-js/react';
import DeviceContextProvider from 'src/app/(providers)/DeviceContext';
import { ThemeProvider } from 'src/app/(providers)/ThemeProvider';
import { Button } from 'src/components/Button/Button';

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const posthog = usePostHog();

  useEffect(() => {
    // Log the error to an error reporting service
    posthog?.captureException(error);
  }, [error]);

  return (
    <html>
      <body className="mt-[--navbarHeight] bg-background pb-[env(safe-area-inset-bottom)] pt-[env(safe-area-inset-top)] font-clash text-primary">
        <ThemeProvider>
          <DeviceContextProvider>
            <div className="flex h-page w-full flex-col items-center">
              <div className="flex h-full flex-col items-center justify-center gap-12">
                <div className="text-center text-3xl sm:text-4xl">
                  Unexpected error occurred
                </div>
                <div className="flex w-full justify-between gap-6">
                  <Link href={'/'}>
                    <Button variant="transparent">
                      <ChevronLeftIcon className="size-5" />
                      back
                    </Button>
                  </Link>
                  <Button onClick={reset}>
                    <RotateCwIcon className="size-5" />
                    Reload
                  </Button>
                </div>
              </div>
            </div>
          </DeviceContextProvider>
        </ThemeProvider>
      </body>
    </html>
  );
}
